<template>
  <div class="cursor-pointer vs-con-loading__container" id="download-btn-loading">
    <div class="available-action flex px-4 py-2" @click="downloadShipments()">
      <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
      <span class="text-sm ml-2">{{ $t('Download') }}</span>
    </div>
  </div>
</template>

<script>
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'
export default {
  props: ['totalRows'],
  data () {
    return {
      headerTitle: ['ShipBlu Inventory ID', 'SKU', 'Product Name', 'Size', 'Incoming Quantity', 'Received Quantity', 'Inbounded Quantity', 'QC Quantity', 'Flags'],
      headerVal: ['id', 'sku', 'name', 'size', 'incoming_quantity', 'received_quantity', 'inbounded_quantity', 'qc', 'flags']
    }
  },
  methods: {
    downloadShipments () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#download-btn-loading',
        scale: 0.45
      })
      const query = `?limit=${this.totalRows}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/${this.$route.name === 'fc-operations-supervisor-stock-recall-view' ? 'stock-recalls' : 'stock-requests'}/${this.$route.params.stockID}/products/${query}`, 'get', null, true,
        (response) => {
          this.stockProducts = response.data.results
          import('@/vendor/Export2Excel').then(excel => {
            const data = this.formatJson(this.headerVal, this.stockProducts)
            excel.export_json_to_excel({
              header: this.headerTitle,
              data,
              filename: this.$route.name === 'fc-operations-supervisor-stock-recall-view' ? 'Stock Recall Products' : 'Stock Request Products',
              autoWidth: true
            })
          })
          this.$vs.loading.close('#download-btn-loading > .con-vs-loading')
        }
      )
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        switch (j) {
        case 'id':
          return v.product.id
        case 'sku':
          return v.product.sku
        case 'name':
          return v.product.name
        case 'size':
          return v.product.size
        case 'incoming':
          return v.payout_method && v.payout_method.bank ? v.payout_method.bank.creditor_name : 'N/A'
        case 'qc':
          return v.qc_status === 'success' ? 'Yes' : v.qc_status === 'failed' ? 'No' : ''
        case 'flags':
          return v.flags.map(item => item.name).join(', ')
        }
        return v[j] ? v[j] : 0
      }))
    }
  }
}
</script>