<template>
  <div>
    <shipblu-card class="mt-4 p-5" :cardLoading="trackingsLoading">
      <p class="text-blue-900 text-xl font-medium leading-tight mb-5">{{$t('Tracking')}}</p>
      <div v-for="(item, index) in trackings" :key="index">
        <ul class="vx-timeline h-20 w-full mb-0 xs:mb-32 sm:mb-0">
          <li>
            <div class="timeline-icon h-2 w-2 inline-block rounded-full mr-2 bg-grey"></div>
            <div class="vx-row flex">
              <div class="timeline-info vx-col w-1/3 sm:w-1/3 xs:w-full xs:mb-1">
                <small class="activity-e-status block">{{$t("Status")}}</small>
                <vs-chip
                  :color="getOrderStatusColor(item)"
                  class="break-all product-order-status">
                    {{ $t(getStatusLabel(item)) }}
                </vs-chip>
              </div>
              <div class="p-0 vx-col xl:pl-0 lg:w-1/3 lg:pr-2 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                <small>{{$t("By")}}</small>
                <p class="font-medium lg:text-sm mr-2">{{item.by ? item.by : '' }}</p>
              </div>
              <div class="p-0 vx-col xl:w-1/3 lg:w-1/4 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                <small>{{$t("Time")}}</small>
                <p class="font-medium lg:text-sm">{{ new Date(item.created).toLocaleDateString('fr-CA') }} {{ new Date(item.created).toLocaleTimeString() }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </shipblu-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import common  from '@/assets/utils/common'
import i18nData from '../../../i18n/i18nData.js'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper.js'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  props: ['stockRequest', 'trackings', 'trackingsLoading'],
  data () {
    return {
      status: '',
      statusLoading: false,
      stockRequestsTrackingEvents: [
        {
          name: 'Ready',
          value: 'ready'
        },
        {
          name: 'Received',
          value: 'received'
        },
        {
          name: 'Inbounded',
          value: 'inbounded'
        },
        {
          name: 'Stored',
          value: 'stored'
        },
        {
          name: 'Failed',
          value: 'failed'
        }
      ]
    }
  },
  components: {
    vSelect,
    ShipbluCard
  },
  methods: {
    formatTime (time) {
      let hr = time.split(':')[0]
      const min = time.split(':')[1]
      const ampm = hr >= 12 ? i18nData[this.$i18n.locale]['pm'] : i18nData[this.$i18n.locale]['am']
      hr = hr > 12 ? hr -= 12 : hr
      return `${hr  }:${  min  }${ampm}`
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    addTracking () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const tracking = {
            stock_request: this.$route.params.stockID,
            status: this.status.value
          }
          this.statusLoading = true
          sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/tracking/`, 'post', tracking, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.status = ''
              this.loadCreatedStocks()
              this.$emit('loadTrackings')
              this.statusLoading = false
            }, (error) => {
              common.notifyResponseError(this, error)
              this.trackingLoading = false
            }
          )
        }
      })
    },
    loadCreatedStocks () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/stock-requests/?status=submitted', 'get', null, true,
        (response) => {
          localStorage.setItem('createdStockRequests', response.data.count)
        }
      )
    }
  }
}
</script>